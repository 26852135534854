import { defineStyleConfig } from '@chakra-ui/react'

const Input = defineStyleConfig({

  baseStyle: {
    field: {
      fontWeight: 700,
    }
  },

  sizes: {
  
  },

  variants: {

  },

  defaultProps: {
   
  },
});

export default Input;